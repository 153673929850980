<template>
  <v-container fluid class="pa-0">
    <portal-target name="inboxSummary" />
  </v-container>
</template>

<script>
export default {
  mixins: [],
  components: {},
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
}
</script>

<style></style>
